<template>
  <div v-if="hasPerm('activities.view_coreactivityinscription')">
    <page-header title="Présences aux activités" icon="fas fa-chess-knight" :links="getLinks()">
    </page-header>
    <div class="head-form">
      <b-row>
        <b-col>
          <b-form-group
            label-for="show-age"
          >
            <b-checkbox
              id="show-age"
              v-model="showAge"
            >
              Afficher l'age
            </b-checkbox>
          </b-form-group>
          <b-form-group
            label-for="show-age"
          >
            <b-checkbox
              id="show-birth"
              v-model="showBirth"
            >
              Afficher la date de naissance
            </b-checkbox>
          </b-form-group>
          <b-form-group
            v-if="hasWaiting"
            label-for="show-waiting"
          >
            <b-checkbox
              id="show-waiting"
              v-model="showWaiting"
            >
              Inclure les en attente
            </b-checkbox>
          </b-form-group>
          <b-form-group
            label-for="show-infos"
            v-if="hasFields"
          >
            <b-checkbox
              id="show-infos"
              v-model="showFields"
              :value="true"
              :unchecked-value="false"
            >
              Afficher les infos
            </b-checkbox>
          </b-form-group>
          <b-form-group
            label-for="show-legend"
            v-if="hasFields && showFields"
          >
            <b-checkbox
              id="show-legend"
              v-model="showLegend"
              :value="true"
              :unchecked-value="false"
            >
              Afficher la légende
            </b-checkbox>
          </b-form-group>
          <b-form-group
            label-for="show-trying-limits"
            v-if="hasTryingLimits"
          >
            <b-checkbox
              id="show-trying-limits"
              v-model="showTryingLimits"
            >
              Afficher les essais arrivés à échéance
            </b-checkbox>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label-for="show-email"
          >
            <b-checkbox
              id="show-email"
              v-model="showEmail"
              :value="true"
              :unchecked-value="false"
            >
              Afficher l'email
            </b-checkbox>
          </b-form-group>
          <b-form-group
            label-for="show-phone"
          >
            <b-checkbox
              id="show-phone"
              v-model="showPhone"
              :value="true"
              :unchecked-value="false"
            >
              Afficher le numéro de téléphone
            </b-checkbox>
          </b-form-group>
          <b-form-group
            label-for="landscape"
          >
            <b-checkbox
              id="landscape"
              v-model="landscape"
            >
              Impression en paysage
            </b-checkbox>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Nombre de séances"
            label-for="seances-count"
            description=""
          >
            <b-input
              id="seances-count"
              type="number"
              step="1"
              min="0"
              max="50"
              v-model="seancesCount"
            >
            </b-input>
          </b-form-group>
          <b-form-group
            label="à partir du"
            label-for="activityDates"
            description=""
            v-if="hasDates"
          >
            <b-input
              id="activityDates"
              type="date"
              v-model="fromDate"
            >
            </b-input>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div ref="printMe">
      <div
        v-for="activityId in arrayActivitiesId"
        :key="activityId"
        class="display-inscriptions docPage"
      >
        <TabActivitiesInscriptions
          :seances-count="seancesCount"
          :show-age="showAge"
          :show-waiting="showWaiting"
          :show-birth="showBirth"
          :show-email="showEmail"
          :show-fields="hasFields > 0 && showFields"
          :show-phone="showPhone"
          :show-legend="showLegend"
          :show-trying-limits="showTryingLimits"
          :activity-id="activityId"
          :from-date="fromDate"
          @fields-loaded="onFieldsLoaded"
          @inscriptions-loaded="onInscriptionsLoaded"
          @dates-loaded="onDatesLoaded"
        />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapMutations, mapActions } from 'vuex'
import PageHeader from '@/components/Layout/PageHeader'
import { BackendMixin } from '@/mixins/backend'
import { openDocument } from '@/utils/http'
import TabActivitiesInscriptions from '@/components/Activities/TabActivitiesInscriptions'
import { dateToString } from '@/filters/texts'

export default {
  name: 'activity-seances',
  computed: {
  },
  props: {
    activityId: String,
    youthHome: [String, Number],
  },
  mixins: [BackendMixin],
  components: {
    TabActivitiesInscriptions,
    PageHeader,
  },
  data() {
    return {
      showAge: true,
      showBirth: false,
      showEmail: false,
      showPhone: false,
      seancesCount: 15,
      hasFields: false,
      showFields: true,
      showLegend: true,
      landscape: true,
      currentTimeFormat: 'llll',
      currentTime: '',
      arrayActivitiesId: [],
      showTryingLimits: true,
      hasTryingLimits: false,
      hasDates: false,
      fromDate: '',
      showWaiting: false,
      hasWaiting: false,
    }
  },
  created() {
    this.hasWaiting = false
    this.activitiesIdToArray()
  },
  watch: {
    showAge: function() {
      window.localStorage.setItem('showAgeActivitySeance', this.showAge ? '1' : '0')
    },
    showBirth: function() {
      window.localStorage.setItem('showBirthActivitySeance', this.showBirth ? '1' : '0')
    },
    showWaiting: function() {
      window.localStorage.setItem('showWaitingActivitySeance', this.showWaiting ? '1' : '0')
    },
    showFields: function() {
      window.localStorage.setItem('showFieldsActivitySeance', this.showFields ? '1' : '0')
    },
    showEmail: function() {
      window.localStorage.setItem('showEmailActivitySeance', this.showEmail ? '1' : '0')
    },
    showPhone: function() {
      window.localStorage.setItem('showPhoneActivitySeance', this.showPhone ? '1' : '0')
    },
    seancesCount: function() {
      window.localStorage.setItem('seancesCountActivitySeance', '' + this.seancesCount)
    },
    showLegend: function() {
      window.localStorage.setItem('showLegendActivitySeance', this.showLegend ? '1' : '0')
    },
    landscape: function() {
      window.localStorage.setItem('landscapeActivitySeance', this.landscape ? '1' : '0')
    },
    fromDate: function() {
      window.localStorage.setItem('fromDateActivitySeance', dateToString(this.fromDate, 'YYYY-MM-DD'))
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    activitiesIdToArray() {
      if (this.activityId.includes('-')) {
        this.arrayActivitiesId = this.activityId.split('-')
      } else {
        this.arrayActivitiesId = [this.activityId]
      }
    },
    getLinks() {
      let links = [
        {
          id: 2,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: (this.isLoading(this.loadingName)) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
      return links
    },
    async printMe() {
      this.currentTime = moment().format(this.currentTimeFormat)
      let docUrl = '/documents/standard/<key>/pdf/?pageBreak=1'
      if (this.landscape) {
        docUrl += '&landscape=1'
      }
      const docSlug = 'activity-seances-' + this.arrayActivitiesId.join('-')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onFieldsLoaded(event) {
      if (event.fields.length) {
        this.hasFields = true
        this.showFields = true
      }
    },
    onInscriptionsLoaded(event) {
      const limits = event.inscriptions.filter(ins => ins.isTryingFinished())
      if (limits.length) {
        this.hasTryingLimits = true
      }
      const waitings = event.inscriptions.filter(ins => ins.waiting)
      if (waitings.length) {
        this.hasWaiting = true
      }
      this.showAge = window.localStorage.getItem('showAgeActivitySeance') !== '0'
      this.showBirth = window.localStorage.getItem('showBirthActivitySeance') === '1'
      this.showWaiting = window.localStorage.getItem('showWaitingActivitySeance') === '1'
      this.showFields = window.localStorage.getItem('showFieldsActivitySeance') === '1'
      this.showEmail = window.localStorage.getItem('showEmailActivitySeance') === '1'
      this.showPhone = window.localStorage.getItem('showPhoneActivitySeance') === '1'
      this.seancesCount = (+window.localStorage.getItem('seancesCountActivitySeance')) || 15
      this.showLegend = window.localStorage.getItem('showLegendActivitySeance') === '1'
      this.landscape = window.localStorage.getItem('landscapeActivitySeance') !== '0'
      this.fromDate = window.localStorage.getItem('fromDateActivitySeance') || ''
    },
    onDatesLoaded(event) {
      if (event.dates.length) {
        this.hasDates = true
      }
    },
  },
}
</script>

<style lang="less" scoped>
.head-form {
  background: #ccc;
  padding: 10px;
}
.inscriptions-list .badge {
  margin: 0 5px;
}
a.field-link.clickable:hover {
  text-decoration: underline;
}
.grouper-header {
  font-size: 20px;
  font-weight: bold;
  background: #444;
  color: #fff;
  padding: 4px 10px;
}
.display-inscriptions {
  margin-top: 40px;
  border-bottom: 1px solid #ccc;
}
</style>
