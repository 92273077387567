import { render, staticRenderFns } from "./ActivitySeances.vue?vue&type=template&id=062bffe3&scoped=true&"
import script from "./ActivitySeances.vue?vue&type=script&lang=js&"
export * from "./ActivitySeances.vue?vue&type=script&lang=js&"
import style0 from "./ActivitySeances.vue?vue&type=style&index=0&id=062bffe3&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "062bffe3",
  null
  
)

export default component.exports