import { render, staticRenderFns } from "./TabActivitiesInscriptions.vue?vue&type=template&id=0aedac34&scoped=true&"
import script from "./TabActivitiesInscriptions.vue?vue&type=script&lang=js&"
export * from "./TabActivitiesInscriptions.vue?vue&type=script&lang=js&"
import style0 from "./TabActivitiesInscriptions.vue?vue&type=style&index=0&id=0aedac34&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0aedac34",
  null
  
)

export default component.exports